import Router from "./router";
import routes from "./routes";

class Utils {
    constructor() {
        this.router = new Router(routes);
    }

    /**
     * 请求后端
     * @returns {*}
     */
    request () {
        return this.router.request.apply(this.router, arguments);
    }

    /**
     * 设置cookie
     * @param name
     * @param value
     * @param expireDay
     */
    setCookie(name, value, expireDay) {
        let expires = '';
        if (expireDay) {
            const d = new Date();
            d.setTime(d.getTime() + (expireDay * 86400 * 1000));
            expires = "expires="+ d.toUTCString() + ';';
        }
        document.cookie = name + "=" + value + ";" + expires + "path=/";
    }

    /**
     * 获取cookie
     * @param cname
     * @returns {string}
     */
    getCookie (cname) {
        const name = cname + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for(let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    }

    /**
     * hash的偏移高度
     * @param hash
     * @returns {*}
     */
    hashOffset (hash) {
        hash = hash ? hash : location.hash;
        const $hash = $(hash);
        if ($hash.length <= 0) {
            return null
        }
        return $hash.offset().top;
    }

    /**
     * 页面滑行到hash节点
     * @param hash
     * @param addOffset
     */
    goHash(hash, addOffset) {
        const offset = this.hashOffset(hash) - (addOffset || 0);
        if (null !== offset) {
            $("html,body").animate({
                scrollTop: offset
            }, 800)
        }
    }
}

Utils.factory = function(){
    if (Utils.instance) {
        return Utils.instance;
    }
    return Utils.instance = new Utils();
};

export default Utils.factory();