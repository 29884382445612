'use strict';

import $  from 'jquery';

function Router(routes)
{
    this.routes = routes || {};

    /**
     * name  路由名
     * requirements 占位符 参数id
     * parameters    data请求参数
     * options   对应ajax 的 options
     */
    this.request = function(name, requirements, parameters, options){
        options = options || {};

        if(typeof requirements === 'function'){
            options.success = requirements;
            requirements = {};
            parameters = {};

        } else if(typeof parameters === 'function'){
            options.success = parameters;
            parameters = {};
        }
        const route = this.getRoute(name, requirements);
        options = $.extend({
            dataType: 'json',
            headers: {},
            url: route[0],
            type: route[1],
            data: parameters instanceof $ ? parameters.serialize() : parameters
        }, options);
        if (options.type.toUpperCase() === 'DELETE') {
            options.headers['x-http-method-override'] = 'DELETE';
        } else if (options.type.toUpperCase() === 'PATCH') {
            options.headers['x-http-method-override'] = 'PATCH';
        }
        return this.lastRequest = $.ajax(options);
    };

    this.getRoutePath = function(name, requirements){
        return this.getRoute(name, requirements)[0];
    };

    this.getRoute = function(name, requirements) {
        if(typeof this.routes[name] !== 'undefined'){
            let method, path;
            if(typeof this.routes[name] === 'object'){
                path = this.routes[name].path;
                method = this.routes[name].method;
            } else {
                path = this.routes[name];
                method = 'GET';
            }
            if(typeof requirements !== 'undefined'){
                if(typeof requirements !== 'object'){
                    requirements = {id: requirements};
                }
            } else {
                requirements = {};
            }
            $.each(requirements, function (i, n){
                path = path.replace('{'+i+'}', n);
            });
            return [path, method];
        }
        return false;
    };

    /**
     * 定义新的路由
     *
     * @param name
     * @param definition
     */
    this.addRoute = function(name, definition){
        this.routes[name] = definition;
    };

    /**
     * 合并新的路由
     * @param newRoutes
     */
    this.addRoutes = function(newRoutes){
        $.extend(thhis.routes, newRoutes);
    };
}

export default Router;