import "../scss/app.scss";
import Utils from "./utils";
import UninstallQuestionnaire from "./uninstall-questionnaire";

if (module.hot) {
    module.hot.accept()
}


class Application{
    constructor(){
        this.initPageHeader();
        this.initUninstallQuestionnaire();
    }

    initPageHeader(){
        const $pageHeader = $('#page-header');
        if ($pageHeader.length > 0) {
            const $window = $(window);
            $window.scroll(function(){
                if ($window.scrollTop() > 80) {
                    $pageHeader.addClass("sticky-fixed");
                }else{
                    $pageHeader.removeClass("sticky-fixed");
                }
            })
        }
        if (window.location.hash) {
            Utils.goHash(location.hash, 140);
        }
        $('.hash-go').each(function(){
            const $this = $(this);
            $this.on('click', ()=>{
                const hash = $this.attr('href').split('#')[1];
                if ($('#' + hash).length > 0) { //本页面存在节点才跳转
                    Utils.goHash('#' + hash, 140);
                    return false;
                }
            });
        });
    }

    initUninstallQuestionnaire(){
        const $form = $('#uninstall-questionnaire-form');
        $form.length > 0 && new UninstallQuestionnaire($form);
    }
}

new Application();