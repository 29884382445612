import Utils from "./utils";
import Notify from './notify.js';
import lockBtn from './button-lock.js';

class UninstallQuestionnaire{
    constructor($form){
        this.$form = $form;
        this.$actionLoader = this.$form.find('.action-loader');
        this.initEvents();
    }

    initEvents(){
        const _this = this;
        const $btn = this.$form.find(':submit');

        this.$form.find(':input').on('change', function(){
            if (_this.$form.find(':checked').length === 0) {
                $btn.attr('disabled', true);
            } else {
                $btn.attr('disabled', false);
            }
        });
        const lock = lockBtn($btn);
        this.$form.on('submit', function(){
            if (lock.isDisabled()) {
                return false;
            }
            lock.lock();
            _this.$actionLoader.addClass('visible');
            $.post(location.href, _this.$form.serialize()).done((response)=>{
                Notify.success('Thanks for your feedback');
                setTimeout(()=>{
                    location.href = '/';
                }, 2000)
            }).always(()=>{
                _this.$actionLoader.removeClass('visible');
            });
            return false;
        });
    }
}

export default UninstallQuestionnaire;